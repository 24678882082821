import { Icon } from '@ics-portal/react';
import clsx from 'clsx';
import { useGlobalUI } from '../../../contexts/GlobalUiContext';
import s from './app-sidebar-toggle.module.scss';

export default function AppSidebarToggle(): JSX.Element {
    const { setAppClassName, appClassName } = useGlobalUI();

    function toggleSidebar(): void {
        setAppClassName((prev) =>
            prev === 'expanded' ? 'collapsed' : 'expanded',
        );
    }

    const iconClasses = clsx(
        s.icon,
        appClassName === 'expanded' ? s.expandedIcon : null,
    );

    return (
        <button
            className={s.toggleButton}
            onClick={toggleSidebar}
            title="Toggle sidebar width"
            type="button"
        >
            <Icon className={iconClasses} name="chevron_right" />
        </button>
    );
}
