import type { ApolloError } from '@apollo/client';
import { ErrorIcon } from './_atoms/icons';
import s from './error-message.module.scss';

interface Props {
    error: ApolloError;
    showOnlyMessage?: boolean;
}

function ErrorMessage({ error, showOnlyMessage }: Props) {
    return (
        <div className={s.styledMessage}>
            <div className={s.heading}>
                <span className={s.bold}>ERROR</span>
                <ErrorIcon className={s.headingIcon} fontSize="small" />
            </div>
            <span>{error.message}</span>
            {error.message
                .toLowerCase()
                .includes('received status code 401') && (
                <p>
                    This might be caused by expired authentication. Please{' '}
                    <a href="/">reload the page and try again</a>.
                </p>
            )}
            {!showOnlyMessage &&
            error.graphQLErrors &&
            error.graphQLErrors.length > 0 ? (
                <div className={s.details}>
                    <span className={s.bold}>
                        {error.graphQLErrors.length} GraphQL errors:
                    </span>
                    {error.graphQLErrors.map((err, index) => (
                        <div className={s.gqlError} key={`error_${index}`}>
                            <div>
                                MESSAGE: <span>{err.message}</span>
                            </div>
                            {err.path ? (
                                <div>
                                    PATH: <span>{err.path.join(' – ')}</span>
                                </div>
                            ) : null}
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
}

export default ErrorMessage;
