import { Flex, ListableInfo } from '@ics-portal/react';
import { useMutation, useQuery } from '@apollo/client';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { ReactNode } from 'react';
import type { UpdateProjectInput } from '~/gql/graphql';
import { GET_PROJECT_STATUSES_AND_PHASES } from '~/api/queries/projects';
import { UPDATE_PROJECT } from '~/api/mutations/projects';
import { useSnackbar } from '~/contexts/GlobalUiContext';
import { useProjectSummary } from '../../../_utils/contexts/ProjectContext';
import s from './project-details.module.scss';

function ProjectDetails(): ReactNode {
    const { project } = useProjectSummary();

    const pushSnackbar = useSnackbar();
    const { data } = useQuery(GET_PROJECT_STATUSES_AND_PHASES, {
        fetchPolicy: 'cache-and-network',
        variables: {
            projectTypeId: project.type?.id ?? '',
        },
        skip: !project.type?.id,
        onCompleted: () => {
            reset({
                id: project.id,
                statusID: project.status?.id,
                phaseID: project.phase?.id,
            });
        },
    });
    const [updateProject] = useMutation(UPDATE_PROJECT);
    const { register, reset, handleSubmit } = useForm<UpdateProjectInput>();

    const onSubmit: SubmitHandler<UpdateProjectInput> = (data) => {
        updateProject({ variables: data })
            .then((response) => {
                pushSnackbar({
                    type: 'success',
                    content: `Project ${response.data?.updateProject?.name} updated.`,
                });
            })
            .catch((err) => {
                pushSnackbar({
                    type: 'error',
                    content: `Error: ${err.message}`,
                });
            });
    };

    return (
        <Flex ai="stretch" direction="column" gap="xs">
            <ListableInfo label="Type:" value={project.type?.name ?? '-'} />
            <ListableInfo
                label="Organization:"
                value={project.organization?.name ?? '-'}
            />
            <form onChange={handleSubmit(onSubmit)}>
                <input id="id" type="hidden" {...register('id')} />
                <ListableInfo
                    label="Status:"
                    value={
                        <select
                            className={s.select}
                            id="statusID"
                            {...register('statusID')}
                        >
                            {data?.projectStatuses.edges.map((edge) => (
                                <option key={edge.node.id} value={edge.node.id}>
                                    {edge.node.name}
                                </option>
                            ))}
                        </select>
                    }
                />
                <ListableInfo
                    label="Phase:"
                    value={
                        <select
                            className={s.select}
                            id="phaseID"
                            {...register('phaseID')}
                        >
                            {data?.projectPhases.edges.map((edge) => (
                                <option key={edge.node.id} value={edge.node.id}>
                                    {edge.node.name}
                                </option>
                            ))}
                        </select>
                    }
                />
            </form>
        </Flex>
    );
}

export default ProjectDetails;
