import { type ReactNode } from 'react';
import s from './box.module.scss';

interface BoxProps {
    children?: ReactNode;
    className?: string;
}

export function Box({ children, className }: BoxProps): ReactNode {
    return <div className={` ${className || ''} ${s.box}`}>{children}</div>;
}
