import type { ReactNode } from 'react';
import type { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Flex } from '@ics-portal/react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import routes from '../../navigation/routes';
import ViewModeSelector from '../../ViewModeSelector';
import s from './content-controller.module.scss';

interface Props {
    title: ReactNode;
    className?: string;
    children?: ReactNode;
}

export default function ContentController({
    className,
    title,
    children,
}: Props) {
    const breadcrumbs = useBreadcrumbs(routes as BreadcrumbsRoute[]);
    const headerClasses = clsx(s.header, className);

    return (
        <header className={headerClasses}>
            <Flex aria-label="breadcrumbs" gap="lg" jc="flex-start">
                {breadcrumbs.map(({ match, breadcrumb }) => (
                    <NavLink
                        className={s.navLink}
                        key={match.pathname}
                        to={match.pathname}
                        end
                    >
                        {breadcrumb}
                    </NavLink>
                ))}
            </Flex>
            <Flex>
                {typeof title === 'string' ? <h1>{title}</h1> : title}
                {children ? children : <ViewModeSelector />}
            </Flex>
        </header>
    );
}
