import type { ReactNode } from 'react';
import {
    Avatar,
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from '@ics-portal/react';
import { type OrganizationUser } from '~/gql/graphql';
import s from './projectUser.module.scss';

export function ProjectIcsoUser({
    projectUser,
}: {
    projectUser: OrganizationUser;
}): ReactNode {
    return (
        <Tooltip placement="left">
            <TooltipTrigger as="div" className={s.projectUser}>
                <Avatar>
                    {projectUser.user.firstName.charAt(0)}
                    {projectUser.user.lastName.charAt(0)}
                </Avatar>
                <div>
                    <sup>{projectUser.organizationRole}</sup>
                    {projectUser.user.firstName} {projectUser.user.lastName}
                </div>
            </TooltipTrigger>
            <TooltipContent>
                <div className={s.locations}>
                    Located in: {projectUser.organization.name}
                    <br />
                    ICSO:{' '}
                    {projectUser.user.organizations
                        ?.map(({ organization: { name } }) => name)
                        .join(', ')}
                </div>
            </TooltipContent>
        </Tooltip>
    );
}
