import main from '../../../constants/menus/default';
import secondary from '../../../constants/menus/secondary';
import { useGlobalUI } from '../../../contexts/GlobalUiContext';
import AppMenu from './AppMenu';
import ProfileLink from './ProfileLink';
import AppLogo from './AppLogo';
import s from './app-sidebar.module.scss';

export default function AppSidebar() {
    const { secondaryMenuVisible, secondaryMenuContent } = useGlobalUI();

    return (
        <div className={s.sidebar}>
            <AppLogo className={s.logo} />
            <div className={s.navigation}>
                <AppMenu menu={main} />
                {secondaryMenuVisible && secondaryMenuContent ? (
                    <AppMenu
                        menu={secondaryMenuContent}
                        className={s.secondaryMenu}
                    />
                ) : null}
            </div>
            <div className={s.footer}>
                <AppMenu menu={secondary} />
                <ProfileLink />
            </div>
        </div>
    );
}
