import { type ReactNode } from 'react';
import { Flex, Icon, type IconTypes } from '@ics-portal/react/src/index';
import { LoadingIndicator } from '@ics-portal/react/src/index';
import { useQuery } from '@apollo/client';
import { GET_PROJECTS_STATS } from '~/api/queries/projects';
import { useMapContext } from '../../../_utils/map-context';
import s from '../project-details-box.module.scss';

interface ProjectDetailsStatProps {
    label: string;
    icon: IconTypes;
    color: string;
    value?: number;
    loading?: boolean;
}
function ProjectDetailsStat({
    label,
    icon,
    color,
    value,
    loading,
}: ProjectDetailsStatProps): ReactNode {
    return loading ? (
        <LoadingIndicator variant="ring" />
    ) : (
        <Flex direction="column" gap="xs" className={`${s.stats} ${color}`}>
            <Icon name={icon} color={color} fontSize="normal" />
            <h2>{value}</h2>
            <div>{label}</div>
        </Flex>
    );
}

export function ProjectDetailsStats(): ReactNode {
    const { project } = useMapContext();

    const { data, loading } = useQuery(GET_PROJECTS_STATS, {
        skip: !project,
        variables: {
            pid: project?.id ?? '',
        },
    });
    if (!project) {
        return null;
    }

    if (loading) {
        return <LoadingIndicator variant="ripple" />;
    }
    return (
        <Flex direction="row">
            <ProjectDetailsStat
                label="pending tasks"
                icon="report"
                color={s.red}
                value={data?.pendingTasks?.totalCount}
            />
            <ProjectDetailsStat
                label="assets"
                icon="apps"
                color={s.yellow}
                value={data?.assets.totalCount}
            />
            <ProjectDetailsStat
                label="components"
                icon="shapes"
                color={s.blue}
                value={data?.components.totalCount}
            />
        </Flex>
    );
}
