export interface LoadingIndicatorProps {
    variant: 'ring' | 'ripple';
    label?: string;
    className?: string;
}

export function LoadingIndicator({
    variant = 'ring',
    label,
    className,
}: LoadingIndicatorProps) {
    return (
        <div className={`lds-container ${className ? className : ''}`}>
            <div className={`lds-${variant}`}>
                <div />
                <div />
                <div />
                <div />
            </div>
            {label ? <div className="lds-label">{label}</div> : null}
        </div>
    );
}
