import { type ReactNode } from 'react';
import { Flex, Icon, IconButton } from '@ics-portal/react/src/index';
import { useMapContext } from '../../../_utils/map-context';
import { getProjectIcon } from '../../../../../utils/project';
import { ProjectMarkerIcon } from '../../../../../components/_atoms/projectMarkerIcon';
import { getProjectStatus } from '../../../_utils/utils';
import s from '../project-details-box.module.scss';

export function ProjectDetailsInfo(): ReactNode {
    const { project, setProject } = useMapContext();
    if (!project) {
        return null;
    }
    return (
        <Flex direction="column" gap="md" ai="stretch">
            <Flex ai="flex-start" jc="space-between" gap="md">
                <h2>{project.name}</h2>
                <IconButton
                    variant="ghost"
                    onClick={() => {
                        setProject(null);
                    }}
                >
                    <Icon name="close" fontSize="small" />
                </IconButton>
            </Flex>
            <Flex jc="space-between" ai="flex-start">
                <div>
                    <h2 className={s.customerName}>{project.customerName}</h2>
                    <p>{project.type?.name}</p>
                    {Boolean(project.organization?.name) && (
                        <p className={s.projectOrganizationName}>
                            {project.organization?.name}
                        </p>
                    )}
                </div>
                <Flex>{getProjectIcon(project.type?.id)}</Flex>
            </Flex>
            <Flex jc="flex-end" className={s.projectStatus}>
                {project.status?.name} - {project.phase?.name}{' '}
                <ProjectMarkerIcon
                    variant="small"
                    status={getProjectStatus(project.status?.name)}
                />
            </Flex>
        </Flex>
    );
}
