import type { DetailedHTMLProps, InputHTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';

interface CheckboxProps
    extends DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    name: string;
    children: ReactNode;
    id?: string;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
    const { name, children, id, checked, ...rest } = props;
    return (
        <label
            className={`ics-checkbox ${rest.disabled && 'disabled'}`}
            htmlFor={id}
        >
            <input
                id={id}
                name={name}
                ref={ref}
                type="checkbox"
                {...rest}
                checked={checked}
            />
            {children}
        </label>
    );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;
