import { type ReactNode } from 'react';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import {
    divIcon,
    type MarkerCluster,
    type DivIcon,
    type LatLngBoundsExpression,
    type LatLngTuple,
} from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { type Project } from '~/gql/graphql';
import useProjects from '../../projects/_utils/hooks/useProjects';
import { ProjectMarker } from './Marker';
import s from './marker.module.scss';

type ProjectWithPoint = Project & { point: NonNullable<Project['point']> };

export function ProjectsMarkers(): ReactNode {
    const map = useMap();
    const { projects } = useProjects();

    const projectsWithPoints = (projects ?? []).filter(
        (project): project is ProjectWithPoint => Boolean(project.point),
    );

    useEffect(() => {
        const points: LatLngBoundsExpression = projectsWithPoints.map(
            ({ point }) => [point.latitude, point.longitude] as LatLngTuple,
        );
        if (!points.length) {
            return;
        }
        map.fitBounds(points, {
            padding: [50, 50],
        });
    }, [map, projectsWithPoints]);

    function createClusterCustomIcon(cluster: MarkerCluster): DivIcon {
        return divIcon({
            html: `<div class="${s.markerCluster}">
                       <div class="${s.markerClusterLabel}">${cluster.getChildCount()}</div>
                       <svg class="${s.markerClusterBackground}" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                           <circle opacity="0.3" cx="17" cy="17" r="17" fill="#006C3B"/>
                           <circle opacity="0.4" cx="17" cy="17" r="12.75" fill="#27B66D"/>
                           <circle opacity="0.5" cx="17" cy="17" r="8.5" fill="#14DA79"/>
                           <circle cx="17.0003" cy="17" r="9.06667" fill="#00FD79"/>
                       </svg>
                   </div>`,
        });
    }
    return (
        <MarkerClusterGroup
            iconCreateFunction={createClusterCustomIcon}
            showCoverageOnHover={false}
        >
            {projectsWithPoints.map((project) => (
                <ProjectMarker key={project.id} project={project} />
            ))}
        </MarkerClusterGroup>
    );
}
