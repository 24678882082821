import type { ReactNode } from 'react';
import { DashboardViewSwitch } from '~/components/_molecules/DashboardViewSwitch/dashboard-view-switch';
import { Map } from '~/components/_organisms/Map/map';
import { useGlobalUI } from '~/contexts/GlobalUiContext';
import { type MapControlContainerProps } from '~/components/_organisms/Map/types';
import {
    MapControlPosition,
    MapControlType,
} from '~/components/_organisms/Map/types';
import { ProjectsProvider } from '../projects/_utils/contexts/ProjectsContext';
import s from './map-page.module.scss';
import { Legend } from './_components/Legend';
import { ProjectsMarkers } from './_components/ProjectsMarkers';
import { ProjectDetailsBox } from './_components/project-details-box/ProjectDetailsBox';
import { MapContextProvider } from './_utils/map-context';

function MapPage(): ReactNode {
    const { appClassName } = useGlobalUI();

    const className = `${s.leafletContainer} ${appClassName === 'expanded' ? s.expanded : s.collapsed}`;

    const controls: MapControlContainerProps[] = [
        {
            position: MapControlPosition.BottomLeft,
            control: <Legend />,
        },
        {
            position: MapControlPosition.BottomRight,
            control: MapControlType.ZOOM,
        },
        { position: MapControlPosition.Right, control: <ProjectDetailsBox /> },
    ];
    return (
        <section className={s.container}>
            <header className={s.header}>
                <div />
                <DashboardViewSwitch />
                <div />
            </header>
            <div className={className}>
                <MapContextProvider>
                    <Map controls={controls}>
                        <ProjectsProvider>
                            <ProjectsMarkers />
                        </ProjectsProvider>
                    </Map>
                </MapContextProvider>
            </div>
        </section>
    );
}

export default MapPage;
