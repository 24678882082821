import { type ReactNode } from 'react';
import { Avatar, Flex, LoadingIndicator } from '@ics-portal/react/src/index';
import { useQuery } from '@apollo/client';
import { GET_PARTICIPANTS_FOR_PROJECT } from '~/api/queries/projects';
import { useMapContext } from '../../../_utils/map-context';
import s from '../project-details-box.module.scss';

export function ProjectDetailsMembers(): ReactNode {
    const { project } = useMapContext();

    const { data, loading } = useQuery(GET_PARTICIPANTS_FOR_PROJECT, {
        skip: !project,
        variables: {
            pid: project?.id ?? '',
        },
    });

    if (!project) {
        return null;
    }

    if (loading) {
        return <LoadingIndicator variant="ripple" />;
    }

    const participants = data?.projects?.edges[0].node.participants;

    if (!participants) {
        return null;
    }
    const longList = participants.length > 6;
    const first = longList ? participants.slice(0, 6) : participants;
    const rest = longList ? participants.length - 6 : 0;

    return (
        <Flex direction="row" jc="space-between">
            <div>Members</div>
            <Flex>
                {first.map((participant) => (
                    <div key={participant?.id} className={s.avatarWrapper}>
                        <Avatar className={s.avatar}>
                            {participant?.user.firstName.charAt(0)}
                            {participant?.user.lastName.charAt(0)}
                        </Avatar>
                    </div>
                ))}
                {rest > 0 && <Avatar>+{rest}</Avatar>}
            </Flex>
        </Flex>
    );
}
