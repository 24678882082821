import { type ReactNode, useState } from 'react';
import { Button } from '@ics-portal/react';
import { useProjectMembers } from '../contexts/ManageProjectContext';
import { ProjectParticipant as Participant } from './ProjectParticipant';
import s from './projectUser.module.scss';

export default function ProjectUsers(): ReactNode {
    const [more, setMore] = useState(false);

    const { selectedMembers: members, selectedICSOs: icsos } =
        useProjectMembers();

    const minimalizedListSize = 3;

    const first = icsos.slice(
        0,
        Math.min(icsos.length, minimalizedListSize + 1),
    );
    const last = icsos.slice(minimalizedListSize + 1, icsos.length);

    if (members.length === 0 && icsos.length === 0)
        return <p>No project members found</p>;

    return (
        <div className={s.list}>
            {members.map((projectUser) => (
                <Participant
                    key={`members-${projectUser.id}`}
                    projectUser={projectUser.participant}
                />
            ))}
            {first.map((projectUser) => (
                <Participant
                    key={`icos-${projectUser.id}`}
                    projectUser={projectUser.participant}
                />
            ))}
            {Boolean(last.length) && (
                <Button
                    className={s.showMore}
                    onClick={() => {
                        setMore(!more);
                    }}
                    variant="ghost"
                    label={
                        more
                            ? 'Hide'
                            : `Show ${last.length.toString()} more participants`
                    }
                />
            )}

            {Boolean(more) &&
                last.map((projectUser) => (
                    <Participant
                        key={`rest-icos-${projectUser.id}`}
                        projectUser={projectUser.participant}
                    />
                ))}
        </div>
    );
}
