import {
    type InternalRefetchQueriesInclude,
    type TypedDocumentNode,
    useMutation,
} from '@apollo/client';
import { makeUniqueId } from '@apollo/client/utilities';
import { type ChangeEvent } from 'react';
import {
    type Scalars,
    type SurveyFormImportMutation,
    type UploadAssetListMutation,
    type UploadStandardsMutation,
    type UploadUserMutation,
    type ComponentVendorAssessmentImportSummaryMutation,
} from '~/gql/graphql';
import { useSnackbar } from '../../../contexts/GlobalUiContext';
import UploadInput from './UploadInput';

interface Props {
    refetchQueries?: InternalRefetchQueriesInclude | undefined;
    mutation: TypedDocumentNode<
        | UploadAssetListMutation
        | UploadStandardsMutation
        | UploadUserMutation
        | ComponentVendorAssessmentImportSummaryMutation
        | SurveyFormImportMutation,
        any
    >;
    id?: string | undefined;
    variables?: { pid: string } | undefined;
    accept?: string | undefined;
    label?: string | undefined;
}

const isUploadStandardsMutation = (
    data: unknown,
): data is UploadStandardsMutation => {
    return (
        typeof data === 'object' &&
        data !== null &&
        'importStandards' in data &&
        data.importStandards !== undefined
    );
};

const isUploadAssetListMutation = (
    data: unknown,
): data is UploadAssetListMutation => {
    return (
        typeof data === 'object' &&
        data !== null &&
        'importAssetsList' in data &&
        data.importAssetsList !== undefined
    );
};

const isUploadUserMutation = (data: unknown): data is UploadUserMutation => {
    return (
        typeof data === 'object' &&
        data !== null &&
        'importUsers' in data &&
        data.importUsers !== undefined
    );
};

const isSurveyFormMutation = (
    data: unknown,
): data is SurveyFormImportMutation => {
    return (
        typeof data === 'object' &&
        data !== null &&
        'surveyFormImport' in data &&
        data.surveyFormImport !== undefined
    );
};

const isUploadSupplierAssessmentsMutation = (
    data: unknown,
): data is ComponentVendorAssessmentImportSummaryMutation => {
    return (
        typeof data === 'object' &&
        data !== null &&
        'importComponentVendorAssessments' in data &&
        data.importComponentVendorAssessments !== undefined
    );
};

export default function UploadHandler({
    id = makeUniqueId('upload-'),
    mutation,
    variables,
    refetchQueries,
    accept = '.xls,.xlsx,.xlsm',
    label,
}: Props): JSX.Element {
    const pushSnackbar = useSnackbar();

    const [upload, { loading, reset }] = useMutation<
        UploadUserMutation | UploadStandardsMutation | UploadAssetListMutation
    >(mutation, {
        refetchQueries,
    });

    function onChange({
        currentTarget: {
            validity: { valid },
            files,
        },
    }: ChangeEvent<HTMLInputElement>) {
        const file: Scalars['Upload'] = files?.item(0);
        if (!valid || file.size === 0) {
            pushSnackbar({
                type: 'error',
                content: 'Invalid file - please try a different one',
            });
            return;
        }

        const mergedVariables = { ...variables, file };

        upload({
            variables: mergedVariables,
        })
            .then(({ data }) => {
                let errors: (string | null)[] | null | undefined;
                if (isUploadSupplierAssessmentsMutation(data)) {
                    errors = data.importComponentVendorAssessments.errors;
                }
                if (isUploadAssetListMutation(data)) {
                    errors = data.importAssetsList.errors;
                }
                if (isUploadStandardsMutation(data)) {
                    errors = data.importStandards.errors;
                }
                if (isUploadUserMutation(data)) {
                    errors = data.importUsers.errors;
                }
                if (isSurveyFormMutation(data)) {
                    errors = data.surveyFormImport.errors;
                }
                if (errors?.length) {
                    pushSnackbar({
                        type: 'error',
                        content: `Error returned from API: ${errors.join(
                            ', ',
                        )}`,
                    });
                    return;
                }
                if (data) {
                    pushSnackbar({
                        type: 'success',
                        content:
                            'Success - some freshly squeezed data was uploaded!',
                    });
                }
            })
            .catch((error) => {
                pushSnackbar({
                    type: 'error',
                    content: `Error returned from API: ${error}`,
                });
            })
            .finally(() => {
                reset();
            });
    }

    return (
        <UploadInput
            id={id}
            label={label}
            onChange={onChange}
            busy={loading}
            accept={accept}
        />
    );
}
