import type {
    ChangeEvent,
    CSSProperties,
    DetailedHTMLProps,
    InputHTMLAttributes,
    ReactNode,
} from 'react';
import { forwardRef } from 'react';

interface TextInputProps
    extends DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    /**
     * unique dom ID
     */
    id: string;
    /**
     * Input name
     */
    name: string;
    /**
     * Input label - optional, if not provided, name is used instead
     */
    label?: string | ReactNode;
    /**
     * Hide the label visually. It will still be readable for screen readers
     */
    hideLabelVisually?: boolean;
    /**
     * Hides the error message space below, allowing for a closer focus ring
     */
    hideErrorMessage?: boolean;
    /**
     * Icon to place in the left side of the input field
     */
    leftIcon?: ReactNode;
    /**
     * Input placeholder - optional
     */
    placeholder?: string;
    /**
     * Input field value
     */
    value?: string;
    /**
     * Input default value - only used if no value is provided
     */
    defaultValue?: string | number;
    /**
     * Is the input disabled?
     */
    disabled?: boolean;
    /**
     * Change handler
     */
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    /**
     * Additional custom styles (overrides)
     */
    style?: CSSProperties;
    /**
     * className string, allowing to extend the component
     */
    className?: string;
    /**
     * Error message to be displayed
     */
    errorMessage?: string;
    /**
     * Allows the input field to be used for date or number type data
     */
    type?: 'date' | 'number';
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
    function TextInput(props, ref) {
        const {
            id,
            name,
            label = name,
            hideLabelVisually = false,
            placeholder = 'Your text here',
            value,
            defaultValue,
            disabled = false,
            onChange,
            style,
            className,
            errorMessage,
            hideErrorMessage,
            leftIcon,
            type,
            ...rest
        } = props;

        const classes = [
            'ics-text-input-compact',
            !leftIcon ? '' : 'ics-text-input--with-icon',
            className,
        ].join(' ');

        return (
            <div className={classes} style={style}>
                {typeof label === 'string' ? (
                    <label
                        className={hideLabelVisually ? 'ics-sr-only' : ''}
                        htmlFor={name}
                    >
                        {label}
                    </label>
                ) : (
                    label
                )}
                <span className="ics-text-input__input-wrapper">
                    {Boolean(leftIcon) && (
                        <span className="ics-text-input__left-icon">
                            {leftIcon}
                        </span>
                    )}
                    <input
                        defaultValue={defaultValue}
                        disabled={disabled}
                        id={id}
                        name={name}
                        onChange={onChange}
                        placeholder={placeholder}
                        ref={ref}
                        type={type ? type : 'text'}
                        value={value}
                        {...rest}
                    />
                </span>

                {!hideErrorMessage && (
                    <div className="ics-text-input__error-container">
                        {errorMessage}
                    </div>
                )}
            </div>
        );
    },
);

TextInput.displayName = 'TextInput';
