import { type ReactNode } from 'react';
import { Button, Icon } from '@ics-portal/react/src/index';
import { Box } from '../../../../components/_atoms/Box/box';
import { useMapContext } from '../../_utils/map-context';
import { Line } from '../../../../components/_atoms/Line/line';
import { ProjectDetailsStats } from './_components/ProjectDetailsStats';
import { ProjectDetailsMembers } from './_components/ProjectDetailsMembers';
import { ProjectDetailsInfo } from './_components/ProjectDetailsInfo';
import { ProjectDetailsActions } from './_components/ProjectDetailsActions';
import s from './project-details-box.module.scss';

export function ProjectDetailsBox(): ReactNode {
    const { project } = useMapContext();

    if (!project) {
        return null;
    }
    return (
        <Box className={s.projectDetails}>
            <ProjectDetailsInfo />
            <Line />
            <ProjectDetailsStats />
            <Line />
            <ProjectDetailsMembers />
            <Line />
            <ProjectDetailsActions />
        </Box>
    );
}
