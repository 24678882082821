import { type ReactNode } from 'react';
import { Box } from '../Box/box';
import s from './box-switch.module.scss';

export interface BoxSwitchElement {
    label?: string;
    icon: ReactNode;
    onClick?: () => void;
    active?: boolean;
}

interface BoxSwitchProps {
    buttons: BoxSwitchElement[];
}

export function BoxSwitch({ buttons }: BoxSwitchProps): ReactNode {
    return (
        <Box className={s.boxSwitch}>
            {buttons.map((button, index) => (
                <div
                    key={button.label ?? index.toString()}
                    onClick={() => {
                        button.onClick?.();
                    }}
                >
                    <div
                        className={`${s.boxSwitchElement} ${button.active ? s.active : ''}`}
                    >
                        {button.label ? <div>{button.label}</div> : null}
                        {button.icon}
                    </div>
                </div>
            ))}
        </Box>
    );
}
