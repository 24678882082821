import { type ReactNode } from 'react';
import { Box } from '~/components/_atoms/Box/box';
import { ProjectStatus } from '~/utils/project';
import s from './legend.module.scss';
import { ProjectStatusElement } from './ProjectStatusElement';

export function Legend(): ReactNode {
    return (
        <Box className={s.legend}>
            <div className={s.legendTile}>Project status</div>
            <ProjectStatusElement status={ProjectStatus.Open} />
            <ProjectStatusElement status={ProjectStatus.InProgress} />
            <ProjectStatusElement status={ProjectStatus.OnHold} />
            <ProjectStatusElement status={ProjectStatus.Closed} />
        </Box>
    );
}
